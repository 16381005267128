import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import hasProfile from '@/middleware/hasProfile'
import noProfile from "@/middleware/noProfile";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/bienvenue',
        component: () => import('@/views/MobileFirstVue.vue'),
        meta: {
            middleware: noProfile
        },
        name: "welcome"
    },
    {
        path: '/dans-votre-poche',
        component: () => import('@/views/MobileSecondVue.vue'),
        meta: {
            middleware: noProfile
        },
        name: "in-your-pocket"
    },
    {
        path: '/choix-profil',
        component: () => import('@/views/ChooseProfile.vue'),
        name: 'profil-choice'
    },
    {
        path: '/home',
        component: () => import('@/views/Home.vue'),
        name: 'home',
        meta: {
            middleware: hasProfile
        }
    },
    {
        path: '/resultats-de-recherche/:universId(\\d+)/:categoryId(\\d+)/:subCategoryId(\\d+)/:subSubCategoryId(\\d+)?/:subSubSubCategoryId(\\d+)?/:filter1?/:filter2?/:filter3?',
        component: () => import('@/views/Results.vue'),
        name: 'results',
        meta: {
            middleware: hasProfile
        }
    },
    {
        path: '/resultats-de-recherche/:universId(\\d+)?/:categoryId(\\d+)?/:subCategoryId(\\d+)?/:subSubCategoryId(\\d+)?/:subSubSubCategoryId(\\d+)?/:search?/:filter1?/:filter2?/:filter3?',
        component: () => import('@/views/Results.vue'),
        name: 'results-text',
        meta: {
            middleware: hasProfile
        }
    },
    {
        path: '/resultats-de-recherche',
        component: () => import('@/views/Results.vue'),
        name: 'results-reference',
        meta: {
            middleware: hasProfile
        }
    },
    {
        path: '/categorie/:univers?/:category?/:subcategory?/:subsubcategory?/:subsubsubcategory?',
        component: () => import('@/views/Category.vue'),
        name: 'category',
        meta: {
            type: 'category',
            middleware: hasProfile
        }
    },
    {
        path: '/produit/:univers/:category/:subcategory?/:subsubcategory?/:subsubsubcategory?/:product',
        component: () => import('@/views/Product.vue'),
        name: 'product',
        meta: {
            type: 'product'
        }
    },
    {
        path: '/mes-favoris',
        component: () => import('@/views/Favories.vue'),
        name: 'favories',
        meta: {
            middleware: hasProfile
        }
    },
    {
        path: '/mentions-legales',
        component: () => import('@/views/legalNotice.vue'),
        name: 'legal_notice',
    },
    {
        path: '/politique-de-confidentialite',
        component: () => import('@/views/privacyPolicies.vue'),
        name: 'privacy_policies',
    },
    {
        path: '/404',
        component: () => import('@/views/404.vue'),
        name: '404'
    },
    {
        path: '/:slug([a-z|\\/|%2F|0-9|-]+)',
        component: () => import('@/views/ContentPage.vue'),
        name: 'cms___fr',
        meta: {
            type: 'cms_page',
            middleware: hasProfile
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

function nextFactory(context: any, middleware: any, index: number) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters: any[]) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({...context, next: nextMiddleware});
    }

    return next();
});

export default router
