<template>
	<div :class="profile" class="layout">
		<ion-page>
			<ion-content>
				<ion-router-outlet :key="$route.path" ref="router" :animation="customAnimation && isMobile ? animation:undefined"/>
			</ion-content>
			<div v-if="memoryAlerte" class="alert-bandeau">
				<div>Mémoire insuffisante pour télécharger tous les médias en hors connexion.</div>
			</div>
		</ion-page>
	</div>
</template>

<script>
import {IonRouterOutlet, createAnimation, IonPage, IonContent} from "@ionic/vue";
import {SettingsActionTypes} from "@/store/modules/settings/action-types";
import {CatalogActionTypes} from "@/store/modules/catalog/action-types";
import {SettingsMutationTypes} from "@/store/modules/settings/mutation-types";
import {NoticesActionTypes} from "@/store/modules/notices/action-types";
import {CatalogMutationTypes} from "@/store/modules/catalog/mutation-types";
import {NoticesMutationTypes} from "@/store/modules/notices/mutation-types";

import { Device } from '@capacitor/device';
import { Network } from '@capacitor/network';
import {mapState} from "vuex";

export default {
	name: "DefaultLayout",
	components: {IonRouterOutlet, IonPage, IonContent},
	inject: ["$file", "$api", '$db'],
	data() {
		return {
			memoryAlerte: false,
			timeout: null,
			customAnimation: false,
			idDevice: null
		}
	},
	computed: {
		...mapState({
			isMobile: state => state.settings.isMobile,
			profile: state => state.settings.profile,
			online: state => state.settings.online,
			categories: state => state.catalog.categories,
			products: state => state.catalog.products,
			universes: state => state.catalog.universes,
			notices: state => state.notices.notices,
			isApp: state => state.settings.isApp
		})
	},
	watch: {
		'$route': function (to, from) {
			this.customAnimation = to.name === 'profil-choice' && from.name !== 'welcome';
		}
	},
	async created() {
		window.addEventListener('resize', this.handleResizeAndOrientation)
		this.updateDeviceDetection()
		const promises = [this.fetchNetworkStatus(), this.fetchDeviceInfo()]
		await Promise.all(promises)
		
		Network.addListener(
			"networkStatusChange",
			status => {
				this.$store.commit(`settings/${SettingsMutationTypes.SET_ONLINE}`, status.connected)
			}
		);
		await this.fetchData(true)
	},
  mounted() {
    this.setConfigAxeptio()
  },
  methods: {
		async fetchNetworkStatus() {
			const status = await Network.getStatus()
			this.$store.commit(`settings/${SettingsMutationTypes.SET_ONLINE}`, status.connected)
		},
		async fetchDeviceInfo() {
			this.idDevice = await Device.getInfo()
			await this.$store.dispatch(`settings/${SettingsActionTypes.PLATFORM_DETECTION}`, this.idDevice)
		},
		updateDeviceDetection() {
			const screenOrientation = screen.orientation
			const type = screenOrientation?.type || null
			const orientation = type?.startsWith("portrait") ? "portrait" : "landscape"
			try {
				this.$store.dispatch('settings/' + SettingsActionTypes.DEVICE_DETECTION, {
					width: window.innerWidth,
					orientation
				})
			} catch (e) {
				console.log(e)
			}
		},
		handleResizeAndOrientation() {
			clearTimeout(this.timeout)
			this.timeout = setTimeout(() => this.updateDeviceDetection(), 1000)
		},
		animation($event, $secondEvent) {
			return createAnimation()
				.addElement($secondEvent.enteringEl)
				.duration(500)
				.fromTo('transform', 'rotateY(0)', 'rotateY(180deg)')
				.fromTo('opacity', '1', '0')
				.addElement($secondEvent.leavingEl)
				.duration(500)
				.fromTo('transform', 'rotateY(180deg)', 'rotateY(0)')
				.fromTo('opacity', '0', '1')
		},
		getCoverRecursive(item, listMedia) {
			const media = {
				type: 'IMG',
				url: item?.cover?.url
			}
			// on filtre les doublons
			const mediaAvailable = listMedia.filter((mediaRegistered) => mediaRegistered.url === media.url)
			if (mediaAvailable.length === 0 && media.url)
				listMedia.push(media)
			if (item?.products?.length > 0) {
				const products = this.products.filter(product => item.products.map(p => p.id).includes(product.id))
				products.forEach((product) => {
					this.getCoverRecursive(product, listMedia)
				})
			}
			if (item?.caracteristics) {
				const images = Object.values(item.caracteristics)?.filter(
					caract => (caract.technical_name.match(/image/) || caract.technical_name.match(/cover/))
						&& !caract.technical_name.match(/image_argument/)
						&& !caract.technical_name.match(/image_mini/))
				listMedia.push(...images.map(image => {
					return {
						type: "IMG",
						url: image.value.url
					}
				}))
			}
			if (item?.caracteristics?.cover?.value?.url)
				listMedia.push({type: 'IMG', url: item.caracteristics.cover.value.url})
			if (item?.caracteristics?.manufacturer?.value?.url) {
				const manufacturerMedia = listMedia.filter((mediaRegistered) => mediaRegistered.url === item.caracteristics.manufacturer.value.url)
				if (manufacturerMedia.length === 0)
					listMedia.push({type: 'IMG', url: item.caracteristics.manufacturer.value.url})
			}
		},
		async fetchData(withMedia = false) {
			if (this.online) {
				await Promise.all([
					this.$store.dispatch(`catalog/${CatalogActionTypes.FETCH_UNIVERSES}`),
					this.$store.dispatch(`catalog/${CatalogActionTypes.FETCH_CATEGORIES}`),
					this.$store.dispatch(`catalog/${CatalogActionTypes.FETCH_PRODUCTS}`),
					this.$store.dispatch(`notices/${NoticesActionTypes.FETCH_NOTICES}`)
				])
				if (this.isApp) {
					await Promise.all([
						this.$db.truncateTable('PRODUCT'),
						this.$db.truncateTable('CATEGORY'),
						this.$db.truncateTable('NOTICE'),
						this.$db.truncateTable('UNIVERSE')
					])
					await Promise.all([
						this.$db.addProductsListOffline(this.products),
						this.$db.addCategoriesListOffline(this.categories),
						this.$db.addNoticesListOffline(this.notices),
						this.$db.addUniversesListOffline(this.universes),
					])
				}
				// télécharger les images de l'arbo produits
				if (withMedia) {
					if (this.idDevice.platform === "android" || this.idDevice.platform === "ios") {
						const listMedia = []
						
						if (this.categories.length > 0) {
							this.categories.forEach((item) => {
								this.getCoverRecursive(item, listMedia)
							})
						}
						this.$file.storeMedia(listMedia)
					}
				}
				// verification de l'espace mémoire
				await this.alertMemory();
			} else {
				const data = await Promise.all([
					this.$db.getAllProducts(),
					this.$db.getAllCategories(),
					this.$db.getAllUniverses(),
					this.$db.getAllNotices()
				])
				const products = data[0]
				this.$store.commit(`catalog/${CatalogMutationTypes.SET_PRODUCTS}`, products)
				
				const categories = data[1]
				this.$store.commit(`catalog/${CatalogMutationTypes.SET_CATEGORIES}`, categories)
				
				const universes = data[2]
				this.$store.commit(`catalog/${CatalogMutationTypes.SET_UNIVERSES}`, universes)
				
				const notices = data[3]
				this.$store.commit(`notices/${NoticesMutationTypes.SET_NOTICES}`, notices)
			}
		},
		async alertMemory() {
			try {
				if (this.isMobile) {
					const memory = await this.$file.getInfoMemory();
					// affiche l'alerte mémoire si l'espace disque est inférieur à la variable ALERTE_MIN_MEMORY_SIZE
					if (memory?.freeDisk && typeof memory?.freeDisk == 'number' && memory?.freeDisk > 0 && memory?.freeDisk < process.env.VUE_APP_ALERTE_MIN_MEMORY_SIZE) {
						this.memoryAlerte = true;
						return true;
					}
				}
			} catch (error) {
				this.memoryAlerte = false;
			}
			this.memoryAlerte = false;
		},
    setConfigAxeptio() {
      const _axcb = window._axcb || []
      _axcb.push((sdk) => {
        sdk.on('cookies:complete', (choices) => {
          this.$store.commit(`settings/${SettingsMutationTypes.SET_CHOICES_AXEPTIO}`, {...choices})
        })
        sdk.on('close', (event) => {
          if (!event)
            setTimeout(() => {
              sdk.openCookies()
            }, 500)
        }, {replay: false})
      })
      window._axcb = _axcb
    },
	}
}
</script>

<style lang="scss" scoped>
.layout {
	&:deep(.ion-page) {
		@media #{$tablethor} {
			background: var(--color-profil);
		}
	}
}

.alert-bandeau {
	background-color: #D10A34;
	width: 100%;
	height: auto;
	z-index: 1000;
	bottom: 0;
	font-size: 11px;
	padding: 4px 20px;
	color: #FFF;
}
</style>
