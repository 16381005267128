import {createApp} from 'vue'
import App from './App.vue'
import router from './router';
import {store} from "@/store";
import {IonicVue} from '@ionic/vue';

import {StatusBar, StatusBarInfo} from "@capacitor/status-bar";
import {Keyboard} from "@capacitor/keyboard";

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import '@/assets/scss/_loader.scss';
import api from "@/plugins/api";
import database from "@/plugins/database";
import file from "@/plugins/file";
import {createGtm} from "vue-gtm";
import { Device } from "@capacitor/device"

const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(store)
    .use(api)
    .use(database)
    .use(file)
    .use(
        createGtm({
            id: process.env.VUE_APP_GTM_CODE,
            // queryParams: {
            //     // Add url query string when load gtm.js with GTM ID (optional)
            //     gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
            //     gtm_preview: "env-4",
            //     gtm_cookies_win: "x",
            // },
            defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
            compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
            nonce: "2726c7f26c", // Will add `nonce` to the script tag
            enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
            debug: true, // Whether or not display console logs debugs (optional)
            loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
            vueRouter: router, // Pass the router instance to automatically sync with router (optional)
            ignoredViews: [], // Don't trigger events for specified router names (case insensitive) (optional)
            trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
        })
    )

router.isReady().then(async () => {
    const infos = await Device.getInfo()
    if (infos.platform !== 'web') {
      StatusBar.getInfo().then((res: StatusBarInfo) => {
        const visible: boolean = res.visible
        StatusBar.setOverlaysWebView({overlay: !visible})
      })
      Keyboard.setScroll({isDisabled: true})
    }
    app.mount('#app');
});
