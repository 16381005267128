<template>
    <ion-app>
        <layout></layout>
    </ion-app>
</template>

<script lang="ts">
import {IonApp} from '@ionic/vue';
import {defineComponent} from 'vue';
import Layout from "@/layout/layout.vue";

export default defineComponent({
    name: 'App',
    components: {
        Layout,
        IonApp
    }
});
</script>
<style scoped>
</style>
